:root {
    --color-primary: #ffffff;
    --color-secondary: #ffffff;
    --color-bg: #EDF3FF;
    --font-primary: 'Montserrat', sans-serif;
    --font-secondary: 'Tapestry', cursive;
}

/* General */

*, html, body {
    font-family: var(--font-primary) !important;
    color: var(--color-primary);
    cursor: none;
}

body {
    color: #ffffff;
    background-color: #222222;
    overflow-x: hidden;
    font-family: var(--font-primary);
}

a {
    color: var(--color-primary);
    text-decoration: none;
}

a:hover {
    color: var(--color-secondary);
}

p {
    font-size: 18px;
    letter-spacing: 0.72px;
    opacity: 0.75;
    line-height: 2;
}

h2 {
    color: var(--color-primary);
    font-size: 50px;
    margin-bottom: 30px;
    opacity: 0.75;
}

h2.type-2 {
    opacity: .3;
    letter-spacing: 6.25px;
    text-transform: uppercase;
}

h3 {
    opacity: 0.8;
    font-size: 20px;
}

section {
    padding-top: 80px;
    /* padding-bottom: 80px; */
}

button {
    cursor: none !important;
}

.entry {
    padding-top: 80px;
}

.fa-flip-horizontal {
    /* -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)"; */
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

/* Header */

#header {
    padding-top: 20px;
    padding-bottom: 20px;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;

    background: rgba(0, 0, 0, 0.002);
    -webkit-backdrop-filter: blur(150px) saturate(1.8);
    backdrop-filter: blur(10px) saturate(1.8);
}

.header {
    position: fixed;
}

.header-home {
    position: fixed;
}

#header .row {
    padding-left: 20px;
    padding-right: 20px;
}

#header hr {
    margin-bottom: unset;
}

#header svg {
    color: #fff;
    font-size: 26px;
    opacity: .7;
    position: relative;
    z-index: 2;
}

#header .menu-icon {
    position: relative;
    z-index: 9999999;
}

#header ul {
    display: flex;
    list-style: none;
    padding-top: 10px;
    justify-content: flex-end;
}

#header ul li {
    padding-left: 15px;
}

.MuiListItemIcon-root {
    min-width: 42px !important;
}

.side-menu a {
    display: block;
    margin-bottom: 30px;
    font-weight: 500;
    letter-spacing: .1175em;
    line-height: 1.875;
    text-transform: uppercase;
    opacity: 0.55;
}

.side-menu a:hover {
    opacity: 1;
}

.side-menu .MuiPaper-root {
    border-radius: 5px;
    padding-right: 130px;
    background: rgba(0, 0, 0, 0.002);
    -webkit-backdrop-filter: blur(150px) saturate(1.8);
    backdrop-filter: blur(150px) saturate(1.8);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    will-change: transform;
    transition: 1s !important;
}

.side-menu .MuiPaper-root .links {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translate(0, -50%);
}

.side-menu .close-icon {
    opacity: 0.5;
    transform: translate(150%, 150%);
}

.typewriter-stroke {
    font-size: calc(10px + 15vmin);
    color: rgb(255, 255, 255);
    -webkit-text-fill-color: rgb(0, 0, 0);
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(255, 255, 255);
}

#slider h1, #slider h2 {
    font-size: 40px;
    font-weight: 300;
    opacity: 1;
    line-height: 70px;
    text-transform: uppercase;
    font-family: 'Puritan', sans-serif;
}

#slider img, #slider video {
    width: 100%;
    height: 100vh;
}

#slider .wrapper {
    position: relative;
}

#slider .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* left: 35%;
    transform: translate(-22%, -50%); */
    text-align: center;
}

#slider .caption button {
    margin-top: 30px;
    padding: 10px 50px 10px 50px;
    border-radius: 25px;
    color: #000000;
    background-color: #fff;
    border-color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
}

#slider .caption .btn-primary:focus {
    box-shadow: unset;
}

#footer {
    background-color: #151515;
    margin-top: 80px;
    padding-top: 40px;
}

#footer .branch {
    text-align: center;
}

#footer .branch-2 {
    text-align: right;
}

#footer ul.social {
    display: flex;
    list-style: none;
    margin-top: 10px;
    padding-left: unset;
    justify-content: flex-end;
    flex-direction: row-reverse;
    opacity: 0.7;
}

#footer ul li {
    padding-right: 15px;
}

#footer h5 {
    font-size: 18px;
    opacity: 0.9;
    text-transform: uppercase;
}

#footer p, #footer a {
    font-size: 14px;
    opacity: 0.8;
}

#footer a:hover {
    opacity: 1;
}

.copyright {
    margin-top: 40px;
    padding-top: 20px;
    padding-bottom: 1px;
    background-color: #000000;
}

.copyright p {
    font-size: 12px !important;
}

.copyright .links {
    text-align: right;
}

.explore .row .col-md-4 {
    margin-bottom: 50px;
}

.explore .info {
    padding: 30px 25px 30px 25px;
    border-left: 1px solid #707070;
}

.explore .info h3 {
    text-transform: uppercase;
}

.explore .info p {
    font-size: 14px;
}

.gallery ul li, .work ul li {
    overflow: hidden;
}

.gallery ul li img, .work ul li img {
    transition: 1s;
}

.gallery ul li:hover img, .work ul li:hover img {
    opacity: 0.7;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    transition: 1s;
}

h1.project {
    font-size: 16px;
    text-transform: uppercase;
}

.project-details {
    margin-top: 10px;
}

.project-details h3 {
    font-size: 12px;
    margin-top: 6px;
    text-transform: uppercase;
}

.project-details h3 span {
    opacity: 0.5;
    text-transform: uppercase;
}

h3.type-2 {
    opacity: 0.8;
    font-size: 50px;
    margin-top: -10px;
    margin-bottom: 40px;
}

ul.type-2 {
    list-style: none;
    text-transform: uppercase;
}

ul.type-2 li {
    opacity: 0.8;
    padding-top: 5px;
    padding-bottom: 5px;
}

.testimonials p {
    line-height: 30px;
}

.testimonials h4 {
    font-size: 18px;
    opacity: .9;
}

.testimonials .actions {
    margin-top: 15px;
    opacity: 0.7;
}

.team {
    padding: 50px;
    background-color: #ffffff2a;
}

.team h3 {
    margin-top: 15px;
    margin-bottom: 45px;
}

.contact-form {
    padding: 40px;
    background-color: #ffffff2a;
}

.contact-form label {
    opacity: .75;
}

.contact-form .form-control {
    color: rgb(255 255 255 / 75%);
    background-color: rgb(255 255 255 / 30%);
    border: 1px solid rgb(255 255 255 / 30%);
}

.contact-form .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
}

.contact-form .btn-secondary {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
    color: rgb(0 0 0 / 75%);
    background-color: rgb(255 255 255 / 50%);
    border-color: rgb(255 255 255 / 50%);
}

.contact .contact-info {
    text-align: center;
}

.contact .contact-info p a:hover {
    opacity: 1;
}

.contact .contact-info p {
    line-height: 30px;
}

.contact .contact-info svg {
    opacity: .8;
}

.contact .MuiChip-root {
    height: 50px;
    border-radius: 30px;
    background-color: rgb(255 255 255 / 50%);
    border: unset;
}

.contact .MuiDivider-root::before, .contact .MuiDivider-root::after {
    width: 22%;
    border-top: thin solid rgb(255 255 255);
}

.contact .MuiDivider-root {
    justify-content: center;
}

.contact .special-label {
    opacity: .8;
    margin-top: 2px;
    margin-bottom: 6px;
}

.work-card {
    padding: 17px;
}

.work-card .thumb {
    position: relative;
    transition: 1s;
    background-color: #fff;
}

.work-card .thumb img {
    transition: 1s;
}

.work-card .thumb:hover img {
    opacity: 0.7;
    transition: 1s;
}

.work-card .thumb h3 {
    font-size: 34px;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
    font-weight: 600;
    letter-spacing: .06em;
    line-height: 1.25em;
}

.work-card p {
    margin-top: 15px;
    font-size: 14px;
    opacity: 1;
}

.clients img {
    margin-top: 10px;
    margin-bottom: 10px;
}

.clients img:hover {
    filter: unset;
}

#header .right .addText {
    color: #ffffff;
    opacity: .8;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

#header .right svg {
    margin-top: -5px;
    font-size: 20px;
}

.work-details section {
    padding-top: unset;
}

.work-details section .container {
    margin-top: 30px;
    margin-bottom: 46px;
}

.work-details h1, .work-details h2 {
    font-size: 12px;
    margin-bottom: 3px;
    text-transform: uppercase;
}

.work-details span.year {
    opacity: 0.5;
    font-size: 13px;
}

.slider-container {
    position: relative;
}

.slider-container .centered {
    position: absolute;
    top: 46%;
    left: 20px;
    /* transform: translate(-50%, -50%); */
}

.slider-container ul {
    list-style: none;
    padding-left: unset;
}

.slider-container ul li {
    margin-top: 5px;
    margin-bottom: 5px;
}

.slider-container ul li.active svg {
    padding: 4px;
    font-size: 17px;
    margin-left: 0px;
    border-radius: 12px;
    opacity: .8;
    border: 1px solid #fff;
}

.slider-container ul li svg {
    opacity: .5;
    font-size: 8px;
    margin-left: 4px;
}

.work-details span.copyright {
    all: unset;
    opacity: 0.3;
    font-size: 13px;
}

h2.copyright.MuiDialogTitle-root {
    color: #ffffff;
    background-color: unset;
}

.model-close svg {
    color: #000000;
}

.copyright-dialog .MuiDialog-paper {
    background-color: #222;
}

.copyright-dialog .MuiDialog-paper p {
    color: #ffffff;
}

.copyright-dialog .MuiDialog-paper button {
    color: #ffffff;
    opacity: .6;
    border: 1px solid rgba(255, 255, 255, 0.5);
}