@media (max-width: 768px) {
    #header {
        padding-top: unset;
        /* backdrop-filter: unset; */
    }

    #header .row {
        padding-left: unset;
        padding-right: unset;
    }

    #header .menu-icon {
        margin-top: 30px;
    }

    #header .logo {
        margin-top: -50px;
    }

    .header {
        position: relative;
    }

    .header-home {
        position: fixed;
    }

    /* #header {
        position: unset;
    } */

    .side-menu .MuiPaper-root {
        padding-right: 50px;
    }

    .side-menu .MuiBox-root {
        width: 260px;
    }

    #slider img, #slider video {
        /* width: unset; */
        width: 100%;
        max-width: 100%;
        padding: 0;
        height: 100vh !important;
        padding-top: 0 !important;
        object-fit: cover;
    }

    .side-menu .MuiPaper-root {
        backdrop-filter: blur(15px) saturate(1.8);
    }

    h2 {
        font-size: 32px;
    }

    p {
        font-size: 16px;
        letter-spacing: 0.52px;
        opacity: 0.75;
        line-height: 1.7;
    }

    .explore .info {
        margin-bottom: 32px;
    }

    #footer {
        padding-top: 40px;
    }

    #footer .branch {
        text-align: left;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    #footer .branch-2 {
        text-align: left;
    }

    .copyright, .copyright .links {
        text-align: center;
    }

    .clients img {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .banner {
        margin-top: 30px;
    }
}