:root {
    --font-primary: 'Montserrat', sans-serif;
    --font-secondary: 'Tapestry', cursive;
}

@mixin gradient-text {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-link:hover .MuiTypography-root {
    font-weight: 600;
    letter-spacing: 5px;
    transition: .1s;
    font-family: var(--font-secondary) !important;
}

.gradient-link._1:hover .MuiTypography-root,
.gradient-link._7:hover .MuiTypography-root {
    @include gradient-text;
    background-image: -webkit-gradient(linear, left top, right top, from(#00c7ff), to(#5225b5));
    background-image: linear-gradient(90deg, #00c7ff, #5225b5);
}

.gradient-link._2:hover .MuiTypography-root,
.gradient-link._8:hover .MuiTypography-root {
    @include gradient-text;
    background-image: -webkit-gradient(linear, left top, right top, from(#af3c72), to(#dc4761));
    background-image: linear-gradient(90deg, #af3c72, #dc4761);
}

.gradient-link._3:hover .MuiTypography-root,
.gradient-link._9:hover .MuiTypography-root {
    @include gradient-text;
    background-image: -webkit-gradient(linear, left top, right top, from(#00c7ff), to(#5225b5));
    background-image: linear-gradient(90deg, #00c7ff, #5225b5);
}

.gradient-link._4:hover .MuiTypography-root,
.gradient-link._10:hover .MuiTypography-root {
    @include gradient-text;
    background-image: -webkit-gradient(linear, left top, right top, from(#6a2ba4), to(#ba3f71));
    background-image: linear-gradient(90deg, #6a2ba4, #ba3f71);
}

.gradient-link._5:hover .MuiTypography-root,
.gradient-link._11:hover .MuiTypography-root {
    @include gradient-text;
    background-image: -webkit-gradient(linear, left top, right top, from(#a9ea18), to(#17b6a8));
    background-image: linear-gradient(90deg, #a9ea18, #17b6a8);
}

.gradient-link._6:hover .MuiTypography-root,
.gradient-link._12:hover .MuiTypography-root {
    @include gradient-text;
    background-image: -webkit-gradient(linear, left top, right top, from(#16a49c), to(#1680bb));
    background-image: linear-gradient(90deg, #16a49c, #1680bb);
}